<template>
    <div class="preset">
        <div class="title">模板样式</div>
        <div class="style-list">
            <div class="style-item" :class="textStyle == item.value ? 'active' : ''" v-for="(item, index) in fontTemplate" :key="index" @click="insertTextPreset(item)">
                <img :src="item.img" alt="">
            </div>
        </div>
        <div class="flex-box">
            <div class="border-style">
                <img src="@/assets/images/creation/creationMng_(68).png" alt="">
                <div class="txt">粗细</div>
                <div class="slider-box">
                    <el-slider size="mini" v-model="style.strokeWidth" :step="1" :min="0" :max="20" :show-tooltip="false"></el-slider>
                </div>
            </div>
            <div class="border-style">
                <div class="txt">{{ style.strokeWidth }}</div>
            </div>
            <el-color-picker class="ml10" v-model="style.strokeColor" size="small"></el-color-picker>
        </div>
    </div>
</template>
<script>
import { mapState } from "vuex"
import {
    mixinVideo
} from "@/mixin/video"
export default {
    mixins: [mixinVideo],
    data() {
        return {
            textStyle: '', // 模板样式
            strokeWidth: 10, // 描边
            strokeColor: '', // 描边颜色
        }
    },
    computed: {
        ...mapState({
            sceneList: state => state.videoData.sceneList,//场景列表
            sceneIndex: state => state.videoData.sceneIndex,//选中场景下标
        }),
        style() {
            if (this.sceneList.length) {
                let ind = this.sceneList[this.sceneIndex].scenes_conf.findIndex(ele => ele.type == 'subtitle')
                if (ind == -1) {
                    return {}
                } else {
                    return this.sceneList[this.sceneIndex].scenes_conf[ind].textStyle
                }
            } else {
                return {}
            }
        },
    },
    methods: {
        insertTextPreset(val) {
            this.textStyle = val
            this.style.color = val.color
            this.style.strokeColor = val.strokeColor
            this.style.strokeWidth = 10
        }
    }
}
</script>
<style lang="scss" scoped>
.preset {
    margin-top: 20px;

    .title {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #c1c1c1;
    }

    .style-list {
        display: flex;
        flex-wrap: wrap;
        margin-right: -20px;
        margin-top: 16px;

        .style-item {
            width: 34px;
            height: 34px;
            background: #272c32;
            border: 1px solid #272c32;
            margin-right: 20px;
            margin-bottom: 10px;
            cursor: pointer;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .style-item.active {
            border-color: $blueColor1;
        }
    }

    .border-style {
        // width: 126px;
        min-width: 32px;
        height: 32px;
        background: #272c32;
        border-radius: 4px;
        border: 1px solid #272c32;
        display: flex;
        align-items: center;
        padding: 8px 12px;
        margin-right: 10px;
        img {
            width: 16px;
            height: 16px;
            margin-right: 2px;
        }

        .txt {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            // margin-left: 12px;
        }

        ::v-deep .slider-box {
            width: 132px;
            margin-left: 12px;
            // margin: 0 12px;

            .el-slider__runway {
                height: 3px;
                margin: 3px 0;
            }

            .el-slider__bar {
                height: 3px;
            }

            .el-slider__button-wrapper {
                height: 32px;
            }

            .el-slider__button {
                width: 7px;
                height: 7px;
                border: 1px solid $blueColor1;
            }
        }
    }

    .flex-box {
        display: flex;
    }
}
</style>