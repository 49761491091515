<template>
    <div class="setcon2">
        <div class="tabs">
            <div class="item" :class="{ 'active': tabValue == item.value, 'item100': ( type == 'subtitle' || type=='dthuman' ||type=='avatar')}" v-for="(item, index) in tabsNew" :key="index" @click="tabChange(item)">
                <div class="label">{{ item.label }}</div>
                <div class="bar"></div>
            </div>
        </div>
        <text-style v-if="tabValue == 1" :type="type" :info="info"></text-style>
        <div class="effect-style scroll_bar" v-if="tabValue == 2 && (type == 'image'||type=='video'||type=='text')">
            <div class="item">
                <div class="title">提示动效</div>
                <div class="animates">
                    <div class="animate" v-for="(item, index) in prompt" :key="index" @click="promtChange(item, index)">
                        <!-- <div class="animate-img" :class="{'active': index == animateValue}"> -->
                        <div class="animate-img" :class="{'active': effects.find(ele=>ele.name==item.type)}">
                            <img class="img" :src="require('@/assets/images/workben/effect_(' + index + ').png')" alt="">
                            <img class="gif-img" v-if="index == 0" :src="require('@/assets/images/workben/effect_(' + index + ').png')" alt="">
                            <img class="gif-img" v-if="index != 0" :src="require('@/assets/images/workben/effect_(' + index + ').gif')" alt="">
                        </div>
                        <div class="animate-name">{{ item.name }}</div>
                    </div>
                </div>
            </div>
            <div class="item">
                <div class="title">入场动效</div>
                <div class="animates">
                    <div class="animate" v-for="(item, index) in in_effect" :key="index" @click="inpromtChange(item, index)">
                        <!-- <div class="animate-img" :class="{'active': index == inanimateValue}"> -->
                        <div class="animate-img" :class="{'active': effects.find(ele=>ele.name==item.type)}">
                            <img class="img" :src="require('@/assets/images/workben/in_(' + index + ').png')" alt="">
                            <img class="gif-img" v-if="index == 0" :src="require('@/assets/images/workben/effect_(' + index + ').png')" alt="">
                            <img class="gif-img" v-if="index != 0" :src="require('@/assets/images/workben/in_(' + index + ').gif')" alt="">
                        </div>
                        <div class="animate-name">{{ item.name }}</div>
                    </div>
                </div>
            </div>
            <div class="item">
                <div class="title">出场动效</div>
                <div class="animates">
                    <div class="animate" v-for="(item, index) in out_effect" :key="index" @click="outpromtChange(item, index)">
                        <div class="animate-img" :class="{'active': effects.find(ele=>ele.name==item.type)}">
                            <!-- <div class="animate-img" :class="{'active': index == outanimateValue}"> -->
                            <img class="img" :src="require('@/assets/images/workben/out_(' + index + ').png')" alt="">
                            <img class="gif-img" v-if="index == 0" :src="require('@/assets/images/workben/effect_(' + index + ').png')" alt="">
                            <img class="gif-img" v-if="index != 0" :src="require('@/assets/images/workben/out_(' + index + ').gif')" alt="">
                        </div>
                        <div class="animate-name">{{ item.name }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex"
import TextStyle from './textStyle/index.vue'
import {
    mixinVideo
} from "@/mixin/video"
export default {
    mixins: [mixinVideo],
    components: {
        TextStyle
    },
    props: {
        type: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            tabs: [
                {
                    label: '样式',
                    value: 1
                },
                {
                    label: '动效',
                    value: 2
                }
            ],
            tabValue: 1,
            animateValue: 0, // 提示动效
            inanimateValue: 0, // 入场动效
            outanimateValue: 0, // 出场动效
            effects: [],
        }
    },
    watch: {
        showId(val) {
            this.tabValue = 1
            this.getEffects(val)
        },
    },
    mounted() {
        this.getEffects(this.showId)
    },
    computed: {
        ...mapState({
            sceneList: state => state.videoData.sceneList,//场景列表
            sceneIndex: state => state.videoData.sceneIndex,//选中场景下标
            showId: state => state.videoData.showId, //选中素材id
        }),
        info() {
            if (this.sceneList.length) {
                let ind = this.sceneList[this.sceneIndex].scenes_conf.findIndex(ele => ele.id == this.showId)
                if (ind == -1) {
                    return {}
                } else {
                    return this.sceneList[this.sceneIndex].scenes_conf[ind]
                }
            } else {
                return {}
            }
        },
        prompt() {
            return this.animates.prompt
        },
        in_effect() {
            return this.animates.in_effect
        },
        out_effect() {
            return this.animates.out_effect
        },
        tabsNew() {
            if (this.type == 'subtitle' || this.type == 'dthuman' || this.type == 'avatar') {
                return [
                    {
                        label: '样式',
                        value: 1
                    },
                ]
            } else {
                return this.tabs
            }
        }
    },
    methods: {
        // 切换选中素材  获取特效列表
        getEffects(val) {
            let arr = this.sceneList[this.sceneIndex].scenes_conf
            let idx = arr.findIndex(ele => ele.id == val)
            this.effects = this.sceneList[this.sceneIndex].scenes_conf[idx].effects || []
        },
        // tab切换
        tabChange(item) {
            this.tabValue = item.value
        },
        // 提示动效
        promtChange(item, index) {
            // this.animateValue = index
            this.addEffects(item, 'prompt')
        },
        // 入场动效
        inpromtChange(item, index) {
            // this.inanimateValue = index
            this.addEffects(item, 'in')
        },
        // 出场动效
        outpromtChange(item, index) {
            // this.outanimateValue = index
            this.addEffects(item, 'out')
        },
        // 添加 或者更换动效
        addEffects(item, type) {
            let idx = this.effects.findIndex(ele => ele.type == type)
            let arr = JSON.parse(JSON.stringify(this.effects))
            if (item.type == 'none') {
                if (idx == -1) {
                    return
                } else {
                    arr.splice(idx, 1)
                }
            } else {
                if (idx == -1) {
                    arr.push({
                        name: item.type,
                        time: 2000,
                        type: type
                    })
                } else {
                    arr[idx] = {
                        name: item.type,
                        time: 2000,
                        type: type
                    }
                }
            }
            this.effects = arr
            this.save(arr)
        },
        save(val) {
            let list = JSON.parse(JSON.stringify(this.sceneList))
            let arr = list[this.sceneIndex]
            let ind = arr.scenes_conf.findIndex(ele => ele.id == this.showId)
            arr.scenes_conf[ind].effects = val
            list[this.sceneIndex] = arr
            var params = {
                type: 'scen',
                info: list
            }
            // 修改数据
            this.$store.dispatch('videoData/modifyProduct', params)
        },
    }
}
</script>

<style lang="scss" scoped>
.setcon2 {
    padding: 20px;
    // height: 100%;
}

.tabs {
    display: flex;
    border-bottom: 1px solid #454a50;

    .item {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .label {
            font-size: 15px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            cursor: pointer;
        }

        .bar {
            width: 28px;
            height: 2px;
            background: transparent;
            border-radius: 1px;
            margin-top: 5px;
        }
    }
    .item100 {
        width: 100%;
        .bar {
            width: 100%;
        }
    }

    .active {
        .label {
            font-weight: 500;
        }

        .bar {
            background: #ffffff;
        }
    }
}

.effect-style {
    padding: 20px 0;
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 320px);
    margin-right: -10px;
    .item {
        .title {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #c1c1c1;
        }

        .animates {
            display: flex;
            flex-wrap: wrap;
            overflow: auto;
            margin-right: -10px;
            margin-top: 16px;

            .animate {
                margin-right: 10px;
                margin-bottom: 20px;
                width: 94px;
                cursor: pointer;
                position: relative;

                .animate-img {
                    width: 94px;
                    height: 60px;
                    background: #272c32;
                    border-radius: 2px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .img {
                        max-width: 100%;
                        max-height: 100%;
                        vertical-align: middle;
                    }
                    .gif-img {
                        max-width: 100%;
                        max-height: 100%;
                        vertical-align: middle;
                        display: none;
                    }
                }
                &:hover {
                    .gif-img {
                        display: block;
                    }
                    .img {
                        display: none;
                    }
                }

                .animate-name {
                    margin-top: 4px;
                    width: 94px;
                    height: 16px;
                    font-size: 12px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #dddde9;
                    line-height: 16px;
                    text-align: center;
                }
                .active {
                    border: 1px solid #2b4afe;
                }
            }
        }
    }
}
</style>