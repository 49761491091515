<template>
    <div class="textStyle scroll_bar">
        <!-- 对齐方式 -->
        <div class="item" v-if="type=='text'||type=='subtitle'">
            <div class="font-weight-box">
                <div class="font-weight-item font-weight-column3" v-for="(item, index) in position_list" :key="index" @click="positionValueChange(item)">
                    <img :src="item.value == info.textStyle.textAlign ? item.imgActive : item.img" alt="">
                </div>
            </div>
        </div>
        <div class="item" v-if="type=='image'||type=='video'">
            <div class="font-weight-box">
                <div class="font-weight-item font-weight-column3" v-for="(item, index) in position_list" :key="index" @click="positionValueChange(item)">
                    <img :src="item.img" alt="">
                </div>
            </div>
        </div>
        <!-- 上传图片 -->
        <div class="item" v-if="type == 'image'" :style="uploadStyleImage">
            <!-- 正在上传 -->
            <div class="uploadbutton activeuploadVideo" v-show="isuploadImage"><i class="el-icon-loading"></i> 正在上传</div>
            <!-- 没有上传 -->
            <el-upload ref="uploadRef" v-show="!isuploadImage" action="#" :show-file-list="false" :accept="formatImage" :http-request="uploadChangeImage">
                <div slot>
                    <div class="uploadbutton noupload"><i class="el-icon-upload el-icon--right"></i> 本地上传</div>
                </div>
            </el-upload>
        </div>
        <!-- 上传视频 -->
        <div class="item" v-if="type == 'video'" :style="uploadStyleVideo">
            <!-- 正在上传 -->
            <div class="uploadbutton activeupload" v-show="isuploadVideo"><i class="el-icon-loading"></i> 正在上传</div>
            <!-- 没有上传 -->
            <el-upload ref="uploadRef" v-show="!isuploadVideo" action="#" :show-file-list="false" :accept="formatVideo" :http-request="uploadChangeVideo">
                <div slot>
                    <div class="uploadbutton noupload"><i class="el-icon-upload el-icon--right"></i> 本地上传</div>
                </div>
            </el-upload>
        </div>
        <div class="item" v-if="type == 'video'">
            <div class="switch-box">

                <div class="label">声音开关</div>
                <div class="">
                    <el-switch v-model="info.audio">
                    </el-switch>
                </div>
            </div>
        </div>
        <div class="item" v-if="type == 'video'">
            <div class="switch-box">
                <div class="label">循环播放</div>
                <div class="">
                    <el-switch v-model="info.loop">
                    </el-switch>
                </div>
            </div>
        </div>
        <div v-if="type == 'subtitle' || type == 'text'">
            <div class="title mt20">字体设置</div>
            <div class="item flex-box">
                <el-select v-model="info.textStyle.fontFamily" placeholder="请选择" size="small">
                    <el-option v-for="item in fontfamilly" :key="item.value" :label="item.label" :value="item.value" :style="{fontFamily: item.value}">
                    </el-option>
                </el-select>
                <el-color-picker class="ml10" v-model="info.textStyle.color" size="small"></el-color-picker>
            </div>
            <div class="item flex-box">
                <el-input-number class="flex1" v-model="info.textStyle.fontSize" controls-position="right" size="small" :min="0"></el-input-number>
                <div class="font-weight-box flex1 ml10">
                    <div class="font-weight-item flex1 font-weight-column2">
                        <img v-if="info.textStyle.fontWeight=='bold'" src="@/assets/images/creation/creationMng_(77).png" alt="" @click="fontweightChange('normal')">
                        <img v-else src="@/assets/images/creation/creationMng_(64).png" alt="" @click="fontweightChange('bold')">

                    </div>
                    <div class="font-weight-item flex1 font-weight-column2">
                        <img v-if="info.textStyle.fontStyle=='italic'" src="@/assets/images/creation/creationMng_(76).png" alt="" @click="fontitalicChange('normal')">
                        <img v-else src="@/assets/images/creation/creationMng_(4).png" alt="" @click="fontitalicChange('italic')">
                    </div>
                </div>
            </div>

            <div class="item">
                <div class="text-wrap">
                    <img src="@/assets/images/creation/creationMng_(9).png" alt="">
                    <div class="txt">字间距</div>
                    <div class="slider-box">
                        <el-slider size="mini" v-model="info.textStyle.letterSpacing" :step="1" :min="0" :max="300" :show-tooltip="false"></el-slider>
                    </div>
                    <div class="txt">{{ info.textStyle.letterSpacing }}px</div>
                </div>
            </div>
            <div class="item">
                <div class="text-wrap">
                    <img src="@/assets/images/creation/creationMng_(10).png" alt="">
                    <div class="txt">行间距</div>
                    <div class="slider-box">
                        <el-slider size="mini" v-model="info.textStyle.lineHeight" :step="0.1" :min="1" :max="4" :show-tooltip="false"></el-slider>
                    </div>
                    <div class="txt">{{ info.textStyle.lineHeight }}倍</div>
                </div>
            </div>
        </div>
        <!-- 模板 -->
        <text-preset v-if="type == 'subtitle'"></text-preset>

        <div class="title mt20">位置及尺寸</div>
        <div class="item flex-box">
            <el-input class="inp94" placeholder="请输入" size="small" @input="limitNum($event,'x')" v-model="info.style.left">
                <template slot="append">X</template>
            </el-input>
            <el-input class="inp94 ml10" placeholder="请输入" size="small" @input="limitNum($event,'y')" v-model="info.style.top">
                <template slot="append">Y</template>
            </el-input>
        </div>
        <div class="item flex-box">
            <el-input class="inp94" placeholder="请输入" size="small" @input="limitNum($event,'w')" v-model="info.style.width">
                <template slot="append">W</template>
            </el-input>
            <el-input class="inp94 ml10" placeholder="请输入" size="small" @input="limitNum($event,'h')" v-model="info.style.height">
                <template slot="append">H</template>
            </el-input>
        </div>
        <div class="item flex-box" v-if="type !== 'subtitle'">
            <el-input class="inp94" placeholder="请输入" size="small" @input="limitNum($event,'r')" v-model="info.style.rotation">
                <template slot="append">
                    <img src="@/assets/images/creation/creationMng_(78).png" alt="">
                </template>
            </el-input>
        </div>
        <video v-if="show" :src="url" id="video"></video>
    </div>
</template>
<script>
import TextPreset from './preset.vue'
import {
    mixinVideo
} from "@/mixin/video"
import { mapState } from "vuex"
export default {
    mixins: [mixinVideo],
    components: { TextPreset },
    props: {
        type: {
            type: String,
            default: ''
        },
        info: {
            type: Object,
            default: {
                style: {},
                textStyle: {}
            }
        },
    },
    data() {
        return {
            // 上传图片
            isuploadImage: false, // 是否正在上传
            progressPercentImage: 0,
            formatImage: 'image/*',
            // 上传视频
            isuploadVideo: false, // 是否正在上传
            progressPercentVideo: 0,
            formatVideo: 'video/mp4, video/webm',
            show: false,
            url: '',
        }
    },
    computed: {
        ...mapState({
            sizeRatio: state => state.videoCreate.sizeRatio,//宽高比2=9:16 1=16:9    
            sceneList: state => state.videoData.sceneList,//场景列表
            sceneIndex: state => state.videoData.sceneIndex,//选中场景下标
        }),
        uploadStyleImage() {
            return {
                '--uploadW': `${this.progressPercentImage}%`
            }
        },
        uploadStyleVideo() {
            return {
                '--uploadWVideo': `${this.progressPercentVideo}%`
            }
        },
    },
    methods: {
        limitNum(e, type) {
            if (e == '') {
                if (type == 'x') {
                    this.info.style.left = 0
                }
                if (type == 'y') {
                    this.info.style.top = 0
                }
                if (type == 'w') {
                    this.info.style.width = 0
                }
                if (type == 'h') {
                    this.info.style.height = 0
                }
                if (type == 'r') {
                    this.info.style.rotation = 0
                }
            } else {
                if (type == 'x') {
                    this.info.style.left = parseInt(e.replace(/[^0-9.]/g, ''))
                }
                if (type == 'y') {
                    this.info.style.top = parseInt(e.replace(/[^0-9.]/g, ''))
                }
                if (type == 'w') {
                    this.info.style.width = parseInt(e.replace(/[^0-9.]/g, ''))
                }
                if (type == 'h') {
                    this.info.style.height = parseInt(e.replace(/[^0-9.]/g, ''))
                }
                if (type == 'r') {
                    this.info.style.rotation = parseInt(e.replace(/[^0-9]/g, ''))
                }
            }
        },

        // 选择对齐方式
        positionValueChange(item) {
            if (this.type == 'text' || this.type == 'subtitle') {
                this.info.textStyle.textAlign = item.value
            } else if (this.type == 'image' || this.type == 'video') {
                if (item.value == 'left') {
                    this.info.style.left = 0
                }
                if (item.value == 'right') {
                    if (this.sizeRatio == 1) {
                        this.info.style.left = Math.floor(1920 - this.info.style.width)
                    } else {
                        this.info.style.left = Math.floor(1080 - this.info.style.width)
                    }
                }
                if (item.value == 'center') {
                    if (this.sizeRatio == 1) {
                        this.info.style.left = Math.floor((1920 - this.info.style.width) / 2)
                    } else {
                        this.info.style.left = Math.floor((1080 - this.info.style.width) / 2)
                    }
                }
            }
            let list = this.getList(this.info.style)
            var params = {
                type: 'conf',
                info: list
            }
            this.$store.dispatch('videoData/modifyProduct', params)
        },
        // 文字加粗
        fontweightChange(val) {
            this.info.textStyle.fontWeight = val
        },
        // 文字倾斜
        fontitalicChange(val) {
            this.info.textStyle.fontStyle = val
        },
        // 图片上传之前的钩子函数
        uploadChangeImage(response) {
            const file = response.file
            const checkSize = file.size / 1024 / 1024 <= 50
            // 判断大小
            if (!checkSize) {
                this.$message({
                    message: `只能上传不超过50M大小的文件`,
                    type: 'warning'
                })
                return false
            }
            // 上传的进度
            const config = {
                onUploadProgress: progressEvent => {
                    this.progressPercentImage = Number((progressEvent.loaded / progressEvent.total * 100).toFixed(2))
                    // console.log(this.progressPercentImage)
                }
            }
            this.isuploadImage = true
            const newFile = new FormData()
            newFile.append('file', file)
            newFile.append('type', 1)
            this.$workbenApi.up_material(newFile, config).then(res => {
                this.$message({
                    showClose: true,
                    message: res.message,
                    type: res.code === 1000 ? 'success' : 'error'
                })
                this.isuploadImage = false
                if (res.code === 1000) {
                    this.info.url = res.result.thumbnail
                }
            })
        },
        // 视频上传之前的钩子函数
        uploadChangeVideo(response) {
            var that = this
            const file = response.file
            const checkSize = file.size / 1024 / 1024 <= 50
            // 判断大小
            if (!checkSize) {
                this.$message({
                    message: `只能上传不超过50M大小的文件`,
                    type: 'warning'
                })
                return false
            }
            // 上传的进度
            const config = {
                onUploadProgress: progressEvent => {
                    this.progressPercentVideo = Number((progressEvent.loaded / progressEvent.total * 100).toFixed(2))
                }
            }
            this.isuploadVideo = true
            const newFile = new FormData()
            newFile.append('file', file)
            newFile.append('type', 4)
            this.$workbenApi.up_material(newFile, config).then(res => {
                this.$message({
                    showClose: true,
                    message: res.message,
                    type: res.code === 1000 ? 'success' : 'error'
                })
                that.isuploadVideo = false
                if (res.code === 1000) {
                    that.url = res.result.url
                    that.info.url = res.result.url
                    that.show = true
                    var obj = {
                        width: 0,
                        height: 0,
                        left: that.info.style.left,
                        top: that.info.style.top,
                        rotation: that.info.style.rotation
                    }
                    that.$nextTick(() => {
                        const video = document.getElementById('video')
                        // 获取视频宽高
                        video.addEventListener('canplay', function (e) {
                            var width = e.target.videoWidth
                            var height = e.target.videoHeight
                            if (that.sizeRatio == 1) {//16:9
                                if (width < 1920 && height < 1080) {
                                    obj.width = width
                                    obj.height = height
                                } else if (width > 1920 && height < 1080) {
                                    var ratio = (1920 / width).toFixed(5)
                                    obj.width = 1920
                                    obj.height = Math.floor(height * ratio)
                                } else if (width < 1920 && height > 1080) {
                                    var ratio = (1080 / height).toFixed(5)
                                    obj.width = Math.floor(width * ratio)
                                    obj.height = 1080
                                } else {
                                    var ratio = ''
                                    var ratio1 = (1920 / width).toFixed(5)
                                    var ratio2 = (1080 / height).toFixed(5)
                                    if ((ratio1 * 100000) > (ratio2 * 100000)) {
                                        ratio = ratio2
                                    } else {
                                        ratio = ratio1
                                    }
                                    obj.width = Math.floor(width * ratio)
                                    obj.height = Math.floor(height * ratio)
                                }
                            }
                            if (that.sizeRatio == 2) {//9:16
                                if (width < 1080 && height < 1920) {
                                    obj.width = width
                                    obj.height = height
                                } else if (width > 1080 && height < 1920) {
                                    var ratio = (1080 / width).toFixed(5)
                                    obj.width = 1080
                                    obj.height = Math.floor(height * ratio)
                                } else if (width < 1080 && height > 1920) {
                                    var ratio = (1920 / height).toFixed(5)
                                    obj.width = Math.floor(width * ratio)
                                    obj.height = 1920
                                } else {
                                    var ratio = ''
                                    var ratio1 = (1080 / width).toFixed(5)
                                    var ratio2 = (1920 / height).toFixed(5)
                                    if ((ratio1 * 100000) > (ratio2 * 100000)) {
                                        ratio = ratio2
                                    } else {
                                        ratio = ratio1
                                    }
                                    obj.width = Math.floor(width * ratio)
                                    obj.height = Math.floor(height * ratio)
                                }
                            }
                            that.info.style = obj
                            let list = that.getList(obj)
                            var params = {
                                type: 'conf',
                                info: list,
                            }
                            that.$store.dispatch('videoData/modifyProduct', params)
                            that.show = false
                            that.url = ''
                        })
                    })
                }
            })
        },
        getList(val) {
            let arr = JSON.parse(JSON.stringify(this.sceneList[this.sceneIndex].scenes_conf))
            arr.forEach(ele => {
                if (ele.id == this.info.id) {
                    ele.style = val
                }
            })
            return arr
        },
    }
}
</script>
<style lang="scss" scoped>
.textStyle {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 320px);

    .title {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #c1c1c1;
    }

    .item {
        margin-top: 16px;

        .font-weight-box {
            height: 32px;
            background: #272c32;
            border-radius: 4px;
            border: 1px solid #272c32;
            display: flex;
            padding: 8px 0;
            width: 100%;

            .font-weight-item {
                border-right: 1px solid #272c32;

                img {
                    cursor: pointer;
                    width: 16px;
                    height: 16px;
                }

                &:nth-last-child(1) {
                    border-right: none;
                }
            }

            .font-weight-column2 {
                display: flex;
                justify-content: center;
            }

            .font-weight-column3 {
                display: flex;
                justify-content: center;
                flex: 1;
            }
        }

        .text-wrap {
            height: 32px;
            background: #272c32;
            border-radius: 4px;
            border: 1px solid #272c32;
            display: flex;
            align-items: center;
            padding: 8px 12px;

            img {
                width: 16px;
                height: 16px;
                margin-right: 2px;
            }

            .txt {
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #ffffff;
                margin-left: 6px;
            }

            ::v-deep .slider-box {
                width: 150px;
                margin: 0 12px;

                .el-slider__runway {
                    height: 3px;
                    margin: 3px 0;
                }

                .el-slider__bar {
                    height: 3px;
                }

                .el-slider__button-wrapper {
                    height: 32px;
                }

                .el-slider__button {
                    width: 7px;
                    height: 7px;
                    border: 1px solid $blueColor1;
                }
            }
        }

        /deep/ .el-select {
            width: 100%;
        }

        /deep/ .el-input__inner {
            background-color: #272c32;
            border: 1px solid #272c32;
            color: #ffffff;
            height: 32px;
            line-height: 32px;
            width: 100%;
        }

        /deep/ .el-input-number__decrease {
            background: #3a4858;
            color: #ffffff;
            border-color: #3a4858;
        }

        /deep/ .el-input-number__increase {
            background: #3a4858;
            color: #ffffff;
            border-color: #3a4858;
        }

        .uploadbutton {
            width: 306px;

            border-radius: 4px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 32px;
            cursor: pointer;
            transition: all 0.3 ease;
            overflow: hidden;
        }

        .activeupload {
            background-color: rgba(72, 117, 255, 0.5);
            position: relative;

            &::after {
                content: "";
                height: 32px;
                position: absolute;
                top: 0;
                left: 0;
                background-color: #4875ff;
                width: var(--uploadW);
                z-index: -1;
            }
        }

        .activeuploadVideo {
            background-color: rgba(72, 117, 255, 0.5);
            position: relative;

            &::after {
                content: "";
                height: 32px;
                position: absolute;
                top: 0;
                left: 0;
                background-color: #4875ff;
                width: var(--uploadWVideo);
                z-index: -1;
            }
        }

        .noupload {
            background: linear-gradient(90deg, #4875ff 0%, #2b4afe 100%);
        }

        .switch-box {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .label {
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #ffffff;
            }
        }
    }

    .flex-box {
        display: flex;
    }

    .flex1 {
        flex: 1;
    }

    .ml10 {
        margin-left: 10px;
    }

    ::v-deep .el-input-group__append,
    ::v-deep .el-input-group__prepend {
        background-color: #3a4858;
        border-color: #3a4858;
        color: #ffffff;
        padding: 0;
        width: 28px;
        text-align: center;

        img {
            width: 16px;
            height: 16px;
            margin: 0 auto;
        }
    }

    .el-input-group {
        // width: 94px;
        width: 147px;
    }

    .mt20 {
        margin-top: 20px;
    }
}
</style>